/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import { PortableText } from "@portabletext/react";
import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import ArticleRelatedContent from "../components/entities/article/articleRelatedContent";
import inLineAd from "../images/plan_calc.jpg";
import mobileInLineAd from "../images/mobile-full-in-lin-ad.png";
import precoaAdDesktop from "../images/grief_horiz_rev_aug2023.jpg";
import precoaAdMobile from "../images/grief_square.jpg";

function mainImageReplace(article) {
  let mainImageReplace = false;
  if (article._rawBody) {
    if (article._rawBody[0]) {
      if (article._rawBody[0].embed) {
        if (
          article._rawBody[0].embed.includes("youtube") ||
          article._rawBody[0].embed.includes("wistia")
        ) {
          mainImageReplace = true;
        }
      }
    }
  }
  return mainImageReplace;
}

const Article = ({ data }) => {
  const article = data.sanityArticle;
  let griefTopic = false;
  if (article?._rawTopics) {
    griefTopic =
      article?._rawTopics[0]?.slug?.current == "grief-support-and-loss"
        ? true
        : false;
  }

  useEffect(() => {
      let inlineLinkAdHref = "https://preplan.today/questions/urgency?utm_source=everplans";
      let inlineAdDesktop = inLineAd;
      let inlineAdMobile = mobileInLineAd;
      if (griefTopic) {
        inlineLinkAdHref =
          "https://form.typeform.com/to/O0dLvfBi?utm_source=everplans";
        inlineAdDesktop = precoaAdDesktop;
        inlineAdMobile = precoaAdMobile;
      }
      let inLineAdLink = document.createElement("a");
      inLineAdLink.href = inlineLinkAdHref;
      inLineAdLink.target = "_blank";

      let inLineAdImg = document.createElement("img");
      inLineAdImg.id = "desktopInLineAdImg";
      inLineAdImg.src = inlineAdDesktop;

      let mobileInLineAdImg = document.createElement("img");
      mobileInLineAdImg.id = "mobileInLineAdImg";
      mobileInLineAdImg.src = inlineAdMobile;

      inLineAdLink.appendChild(inLineAdImg);
      inLineAdLink.appendChild(mobileInLineAdImg);

      let inLineAdContainer = document.createElement("div");
      inLineAdContainer.id = "inLineAdLink";
      inLineAdContainer.appendChild(inLineAdLink);

      if (
        document
          .getElementById("article-full-body")
          .getElementsByTagName("h2")[1]
      ) {
        document
          .getElementById("article-full-body")
          .getElementsByTagName("h2")[1]
          .before(inLineAdContainer);
      } else {
        document.getElementById("article-full-body").append(inLineAdContainer);
      }
  }, []);
  let articlePath =
    article.post_type === "article"
      ? "https://www.everplans.com/articles/" + article.slug.current
      : "https://www.everplans.com/blog/" + article.slug.current;

  useEffect(() => {
    const script = document.createElement("script");
    const topics = article._rawTopics.map((topic) =>
      topic.name ? topic.name.toLowerCase() : ""
    );

    script.setAttribute("data-ot-ignore", "");
    script.setAttribute("data-topics", JSON.stringify(topics));
    script.setAttribute("id", "quiz-widget");
    script.setAttribute("type", "module")
    
    script.src = `${process.env.GATSBY_QUIZ_WIDGET_HOST}/quizWidgetApp.js?` +
      new Date().getTime();
    
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [article._rawTopics]);

  return (
    <Layout>
      <Helmet>  
        <link
          href={`${process.env.GATSBY_QUIZ_WIDGET_HOST}/quizWidgetApp.css`}
          rel="stylesheet" 
        />
      </Helmet>
      <SEO
        title={article.headline}
        lang="en"
        description={article._rawIntroText}
      ></SEO>

      <Container>
        <div
          sx={{
            margin: ["50px 0px", "50px 0px", "100px 0px"],
          }}
        >
          {article.headline && (
            <h1
              id="article-title"
              sx={{
                fontSize: ["2.75rem", "3rem"],
                margin: "0px 0px 10px 0px",
                lineHeight: ["2.875rem", "3.4rem"],
              }}
            >
              {article.headline}
            </h1>
          )}
          {article._rawIntroText && (
            <div
              sx={{
                margin: "0px",
                color: "textGray",
                fontSize: "1.4rem",
                lineHeight: "2.1rem",
                a: {
                  color: "textGray",
                },
                p: {
                  margin: "0px",
                  fontSize: "1.4rem",
                  lineHeight: "2.1rem",
                },
              }}
            >
              <PortableText
                value={article._rawIntroText}
                components={PortableTextComponent}
              />
            </div>
          )}
          <div
            sx={{
              display: "flex",
              margin: "25px 0px 0px 0px",
              alignItems: "center",
            }}
            className="article-category-social"
          >
            {article._rawCategory && article._rawCategory.name && (
              <div
                className="article-category"
                sx={{
                  color: "primary",
                  fontSize: ["1.125rem", "1.2rem"],
                  textDecoration: "none",
                  textTransform: "uppercase",
                  maxWidth: "calc(100% - 150px)",
                }}
              >
                {article._rawCategory.name}
              </div>
            )}
            {article.post_type === "blog_post" && (
              <React.Fragment>
                {article._rawTopics && (
                  <div
                    sx={{
                      marginRight: "20px",
                      fontSize: "1.2rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {article._rawTopics[0] && article._rawTopics[0].name}
                  </div>
                )}
                <div sx={{}}>{article.publish_date}</div>
              </React.Fragment>
            )}
            <ul
              className="article-share-icons"
              sx={{
                marginLeft: "1.5rem",
                width: "150px",
                display: "flex",
                padding: "0px",
                li: {
                  listStyle: "none",
                  width: "30px",
                  height: "30px",
                  "&:nth-child(2)": {
                    margin: "0px 10px",
                  },
                  a: {
                    "&:hover": {
                      img: {
                        filter:
                          "brightness(0) saturate(100%) invert(35%) sepia(42%) saturate(6218%) hue-rotate(231deg) brightness(102%) contrast(96%)",
                      },
                    },
                  },
                },
              }}
            >
              <li>
                <a
                  href={`https://twitter.com/share?text=${article.headline}&url=${articlePath}`}
                  aria-labelledby="article-title article-share-twitter"
                  target="_block"
                >
                  <img alt="twitter icon" src={data.twitterIcon.publicURL} />
                  <title id="article-share-twitter">Share on Twitter</title>
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/share.php?u=${articlePath}`}
                  aria-labelledby="article-title article-share-facebook"
                  target="_block"
                >
                  <img alt="facebook icon" src={data.facebookIcon.publicURL} />
                  <title id="article-share-facebook">Share on Facebook</title>
                </a>
              </li>
              <li>
                <a
                  href={`mailto:?subject=${article.headline}&body=Check out this article ${articlePath}`}
                  target="_block"
                  aria-labelledby="article-title article-share-email"
                >
                  <img alt="email icon" src={data.emailIcon.publicURL} />
                  <title id="article-share-email">Share by Email</title>
                </a>
              </li>
            </ul>
          </div>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
              flexWrap: "wrap",
            }}
          >
            <div
              sx={{
                width: [
                  "100%",
                  "100%",
                  "calc(70% - 50px)",
                  // EOL node sidebar
                  // endOfLife || griefTopic ? "100%" : "calc(70% - 50px)",
                ],
              }}
            >
              {article.main_image && !mainImageReplace(article) && (
                <div className="article-full-main-image">
                  <GatsbyImage
                    sx={{
                      borderRadius: "8px",
                      filter: "brightness(95%)",
                    }}
                    image={article.main_image.asset.gatsbyImageData}
                  />
                </div>
              )}
              {article._rawBody && (
                <div
                  id="article-full-body"
                  sx={{
                    margin: "35px 0px 0px 0px",
                    fontSize: "body",
                    color: "textGray",
                    "h1,h2,h3,h4,h5,h6": {
                      color: "primary",
                      margin: "2rem 0px .7rem 0px",
                    },
                    a: {
                      color: "textGray",
                    },
                    p: {
                      lineHeight: "2rem",
                      margin: ".7rem 0rem",
                    },
                    ul: {
                      margin: "0rem",
                    },
                    li: {
                      lineHeight: "2.2rem",
                    },
                    ".gatsby-image-wrapper": {
                      margin: "2.5rem 0rem",
                      display: "inline-block",
                      maxWidth: "100%",
                      borderRadius: "8px",
                      "> div": {
                        maxWidth: "100% !important",
                      },
                    },
                    ".ep-quiz-widget": {
                      width: "100% !important",
                      position: "relative !important",
                      top: "0px !important",
                      marginTop: "20px !important",
                      marginBottom: "20px !important",
                    },
                    "#inLineAdLink": {
                      display: article.post_type === "article"
                      ? "flex" : "none",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "40px",
                      padding: [
                        "0px 0px 8px 0px",
                        "0px 4.5% 8px 4.5%",
                        "0px 4.5% 8px 4.5%",
                      ],
                      boxSizing: "border-box",
                      a: {
                        maxWidth: ["400px", "1120px", "1120px"],
                        img: {
                          "&#desktopInLineAdImg": {
                            display: [
                              "none",
                              "flex",
                              "flex",
                            ],
                          },
                          "&#mobileInLineAdImg": {
                            display: [
                              "flex",
                              "none",
                              "none",
                            ],
                          },
                        },
                      },
                    },
                  }}
                >
                  <PortableText
                    value={article._rawBody}
                    components={PortableTextComponent}
                  />
                </div>
              )}
              {article._rawAuthors && (
                <div
                  className="article-author"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    fontFamily: "primary",
                    marginTop: "2rem",
                  }}
                >
                  WRITTEN BY {article._rawAuthors[0].name}
                </div>
              )}
              {article.post_type === "article-disabled" && (
                <div>
                  <div
                    sx={{
                      fontSize: "2rem",
                      fontFamily: "primary",
                      margin: "3rem 0rem 1.438rem 0rem",
                    }}
                  >
                    Ready to get started?
                  </div>
                  <a
                    sx={{
                      variant: "buttons.primaryBlue",
                      display: "inline-block",
                    }}
                    href="#"
                  >
                    Sign Up Now
                  </a>
                </div>
              )}
              {article._rawTopics && (
                <div
                  sx={{
                    marginTop: "2.625rem",
                  }}
                >
                  <div
                    sx={{
                      fontFamily: "primary",
                      fontSize: "1.5rem",
                    }}
                  >
                    Topics
                  </div>
                  <ul
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "0rem",
                      marginBottom: "0px",
                    }}
                  >
                    {article._rawTopics.map(
                      (tag, index) =>
                        tag.slug && (
                          <li
                            sx={{
                              listStyle: "none",
                              marginRight: "0.75rem",
                              marginBottom: "0.75rem",
                            }}
                            key={index}
                          >
                            <Link
                              sx={{
                                variant: "buttons.primaryWhite",
                              }}
                              to={
                                article.post_type === "article"
                                  ? "/" + tag.slug.current
                                  : "/news"
                              }
                            >
                              {tag.name}
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )}
            </div>
            {article.post_type === "article" && (
              <div
                className="ep-quiz-widget"
                sx={{
                  width: ["100%", "100%", "30%"],
                  height: "100%",
                  display: "inline-block",
                  position: ["relative", "sticky"],
                  top: ["0px", "0px", "124px"],
                  marginTop: ["40px", "40px", "0px"],
                  marginBottom: "15px",
                  // display settings if revert back to no sidebar for EOL
                  // display: endOfLife || griefTopic ? "none" : "block",
                  h1: {
                    fontSize: "2em",
                  },
                }}
              >
                {/* The quiz widget is injected here */}
              </div>
            )}
          </div>
        </div>
      </Container>
      {article.post_type === "article-disabled" && (
        <div
          sx={{
            backgroundColor: "primary",
            padding: "4.375rem 0px",
          }}
        >
          <Container>
            <div
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                textAlign: ["center", "left"],
              }}
            >
              <div
                sx={{
                  width: ["100%", "70%"],
                }}
              >
                <div
                  sx={{
                    color: "#ffffff",
                    fontSize: ["2rem", "3rem"],
                    fontFamily: "primary",
                  }}
                >
                  Nullam id dolor id nibh.
                </div>
                <div
                  sx={{
                    color: "#ffffff",
                    fontSize: "1rem",
                    margin: ["10px 0px 30px 0px", "10px 0px 30px 0px", "0px"],
                  }}
                >
                  Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem.
                </div>
              </div>
              <div
                sx={{
                  width: ["100%", "70%"],

                  display: "flex",
                  alignItems: "center",
                  justifyContent: ["center", "flex-start"],
                  width: ["100%", "auto", "auto"],
                }}
              >
                <Link
                  sx={{
                    variant: "buttons.primaryWhite",
                  }}
                  to="#"
                >
                  Download Now
                </Link>
              </div>
            </div>
          </Container>
        </div>
      )}
      {article.post_type === "article" && (
        <div>
          <Container>
            {data.relatedContent && (
              <div
                sx={{
                  padding: "0px 0px 80px 0px",
                  marginTop: "30px",
                }}
              >
                <div
                  sx={{
                    fontSize: "3rem",
                    marginBottom: "35px",
                    lineHeight: "3.4rem",
                  }}
                >
                  Related Content
                </div>
                <ul
                  sx={{
                    padding: "0px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {data.relatedContent.nodes.map((relatedContent, index) => (
                    <li
                      key={index}
                      sx={{
                        listStyle: "none",
                        width: ["100%", "100%", "calc(50% - 28px)"],
                        marginBottom: "64px",
                      }}
                    >
                      <ArticleRelatedContent article={relatedContent} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </div>
      )}
    </Layout>
  );
};
export default Article;

export const ArticleQuery = graphql`
  query ArticleBySlug($slug: String!, $categoryId: String!) {
    relatedContent: allSanityArticle(
      limit: 4
      sort: { fields: [pinned, publish_date], order: [DESC, DESC] }
      filter: {
        category: { _id: { eq: $categoryId } }
        slug: { current: { ne: $slug } }
        post_type: { eq: "article" }
        _id: { regex: "/^(?!draft).*$/" }
      }
    ) {
      nodes {
        _rawIntroText(resolveReferences: { maxDepth: 10 })
        _rawBody(resolveReferences: { maxDepth: 10 })
        _rawCategory(resolveReferences: { maxDepth: 10 })
        post_type
        main_image {
          asset {
            gatsbyImageData(width: 1024)
          }
        }
        slug {
          current
        }
        headline
        short_title
      }
    }
    facebookIcon: file(name: { eq: "Facebook-Subtract2" }) {
      publicURL
    }
    twitterIcon: file(name: { eq: "Twitter-Subtract2" }) {
      publicURL
    }
    emailIcon: file(name: { eq: "Email-Subtract2" }) {
      publicURL
    }
    sanityArticle(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      publish_date(formatString: "MMMM D, Y")
      post_type
      headline
      main_image {
        asset {
          gatsbyImageData(width: 1500)
        }
      }
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawAuthors(resolveReferences: { maxDepth: 10 })
      _rawCategory(resolveReferences: { maxDepth: 10 })
      _rawTopics(resolveReferences: { maxDepth: 10 })
    }
  }
`;
